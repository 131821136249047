<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(show_factory)="data">

        <div class="text-center">
          <span
            v-if="viewContent"
            title="View"
          >
            <feather-icon
              icon="EyeIcon"
              class="cursor-pointer"
              @click="
                $router.push({
                  name: 'show-facility',
                  params: { id: data.item.id }
                })
              "
            />
          </span></div></template>

      <template #cell(activityar)="data">
        <div class="text-center">
          <span
            v-if="data.item.activity"
            title="View"
            style="text-wrap: nowrap;"
          >
            {{ data.item.activity.ar || ' ' }}
          </span></div></template>

      <template #cell(activityen)="data">
        <div class="text-center">
          <span
            v-if="data.item.activity"
            title="View"
            style="text-wrap: nowrap;"
          >
            {{ data.item.activity.en || ' ' }}
          </span></div></template>

      <template #cell(nameen)="data">
        <div class="text-center">
          <span
            v-if="data.item.name"
            title="View"
            style="text-wrap: nowrap;"
          >
            {{ data.item.name.en || ' ' }}
          </span></div></template>

      <template #cell(namear)="data">
        <div class="text-center">
          <span
            v-if="data.item.name"
            title="View"
            style="text-wrap: nowrap;"
          >
            {{ data.item.name.ar || ' ' }}
          </span></div></template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      viewContent: true,
      type: 'page',
      filter: {},
      columns: [
        { key: 'id', sortable: true },
        { key: 'namear', label: 'Name Ar' },
        { key: 'nameen', label: 'Name En' },
        { key: 'activityar', label: 'Activity Ar' },
        { key: 'activityen', label: 'Activity En' },
        { key: 'CR_num', label: 'CR Number' },
        { key: 'CR_end_date', label: 'CR End Date' },
        { key: 'phone', label: 'Phone' },
        // { key: 'address', label: 'Address' },
        { key: 'zip_code', label: 'Zip Code' },
        { key: 'postal_code', label: 'Postal Code' },
        { key: 'audit_license_expiration', label: 'Audit License Expiration' },
        { key: 'survialnce', label: 'Survialnce' },
        { key: 'show_factory', label: 'Show Info' },
        // { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      const base = 'factory?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },
  },
  mounted() {
    // console.log('Count in mounted:', this.role)
  },

  methods: {
    filterTypes(type) {
      this.types = this.status.filter(item => item.text !== type)
    },
  },
}
</script>

<style></style>
